<template>
  <b-card>
    <b-skeleton
      v-if="loading"
      animation="wave"
      width="100%"
      height="300px"
    />
    <table
      v-else
      class="table table-no-horizontal-border"
    >
      <thead>
        <tr>
          <td />
          <th
            v-for="column in departamentalSchedules"
            :key="column.id"
            class="text-center"
          >
            {{ column.day | formatDay }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            class="d-flex justify-content-start align-items-center"
            style="gap: 1rem;"
          >
            <span
              class="d-flex justify-content-center bg-primary"
              style="padding: .7rem; border-radius: 7px;"
              :style="isDarkSkin ? 'background-color: #1C3238 !important;' : 'background-color: #E8FFF3 !important;'"
            >
              <feather-icon
                icon="SunriseIcon"
                size="25"
                style="color: #50CD89;"
              />
            </span>
            <span>
              Start hour:
            </span>
          </td>
          <td
            v-for="column in departamentalSchedules"
            :key="column.id"
          >
            <b-form-select
              v-model="column.start_hour"
              :options="timeOptions"
              :class="isDarkSkin ? 'border-0' : ''"
              :style="isDarkSkin ? 'background-color: #292D32;' : 'background-color: #FEFFFE;'"
            />
          </td>
        </tr>
        <tr>
          <td
            class="d-flex justify-content-start align-items-center"
            style="gap: 1rem;"
          >
            <span
              class="d-flex justify-content-center bg-primary"
              style="padding: .7rem; border-radius: 7px;"
              :style="isDarkSkin ? 'background-color: #3A2434 !important;' : 'background-color: #FFF5F8 !important;'"
            >
              <feather-icon
                icon="SunsetIcon"
                size="25"
                style="color: #F0416D;"
              />
            </span>
            <span>
              End hour:
            </span>
          </td>
          <td
            v-for="column in departamentalSchedules"
            :key="column.id"
          >
            <b-form-select
              v-model="column.end_hour"
              :options="timeOptions"
              :class="isDarkSkin ? 'border-0' : ''"
              :style="{
                'border': arrayErrorsSchedule.includes(column.id) ? '1px solid red' : '',
                'background-color': isDarkSkin ? '#292D32' : '#FEFFFE'
              }"
            />
            <div class="d-flex justify-content-start w-auto">
              <small
                v-if="arrayErrorsSchedule.includes(column.id)"
                class="text-danger w-auto"
              >Greater than Start Hour</small>
            </div>
          </td>
        </tr>
        <tr>
          <td
            class="d-flex justify-content-start align-items-center"
            style="gap: 1rem;"
          >
            <span
              class="d-flex justify-content-center bg-primary"
              style="padding: .7rem; border-radius: 7px;"
              :style="isDarkSkin ? 'background-color: #202E49 !important;' : 'background-color: #F1FAFF !important;'"
            >
              <feather-icon
                icon="TypeIcon"
                size="25"
                class="text-primary"
              />
            </span>
            <span>
              Type:
            </span>
          </td>
          <td
            v-for="column in departamentalSchedules"
            :key="column.id"
          >
            <b-form-select
              v-model="column.remote_state"
              variant="outline-primary"
              :options="type"
              :class="isDarkSkin ? 'border-0' : ''"
              :style="isDarkSkin ? 'background-color: #292D32;' : 'background-color: #FEFFFE;'"
            />
          </td>
        </tr>
        <tr>
          <td
            class="d-flex justify-content-start align-items-center"
            style="gap: 1rem;"
          >
            <span
              class="d-flex justify-content-center"
              style="padding: .7rem; border-radius: 7px;"
              :style="isDarkSkin ? 'background-color: #202E49 !important;' : 'background-color: #F1FAFF !important;'"
            >
              <feather-icon
                icon="ToolIcon"
                size="25"
                class="text-primary"
              />
            </span>
            <span>
              Actions:
            </span>
          </td>
          <td
            v-for="column in departamentalSchedules"
            :key="column.id"
          >
            <div
              class="d-flex"
              style="justify-content: space-evenly;"
            >
              <span
                class=" cursor-pointer bg-primary"
                style="padding: 8px; border-radius: 10px"
              >
                <feather-icon
                  v-b-tooltip.hover
                  icon="CopyIcon"
                  size="20"
                  title="Copy hour range to other days"
                  style="color: white !important;"
                  @click="copyHours(column.start_hour, column.end_hour)"
                />
              </span>
              <span
                class="cursor-pointer bg-warning"
                style="padding: 8px; border-radius: 10px"
              >
                <feather-icon
                  v-b-tooltip.hover
                  icon="SaveIcon"
                  size="20"
                  title="Save this day"
                  style="color: white !important;"
                  @click="updateDepartamentalScheduleId(column)"
                /></span>
              <span
                class="cursor-pointer"
                style="background-color: #8E5FE9; padding: 8px; border-radius: 10px"
              >
                <feather-icon
                  v-b-tooltip.hover
                  icon="ListIcon"
                  size="20"
                  title="Tracking of this day"
                  style="color: white !important;"
                  @click="tracking(column.day)"
                /></span>

            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <caption class="d-flex">Departamental Schedules</caption> -->
    <div class="d-flex justify-content-between align-items-center mt-5">
      <span>Departamental Schedules</span>
      <button
        type="button"
        class="btn btn-success"
        @click="updateDepartamentalSchedules()"
      >
        <strong>SAVE</strong>
      </button>
    </div>

    <b-modal
      v-model="modal"
      hide-footer
      :title="`Tracking ${modalTitle}`"
    >
      <b-table
        striped
        hover
        :fields="fields"
        :items="selectedDepartamentalSchedules"
      >
        <template #cell(updated_by)="data">
          <div v-if="data.item.updated_at">
            <span>
              <feather-icon
                icon="UserIcon"
                size="20"
              />
              {{ data.item.updated_by }}
            </span>
            <br>
            <span>
              <feather-icon
                icon="CalendarIcon"
                size="20"
              />
              {{ data.item.updated_at | myGlobal }}
            </span>
          </div>
          <span v-else> - </span>
        </template>

        <template #cell(remote_state)="data">
          <span>
            {{ data.item.remote_state | state }}
          </span>
        </template>
      </b-table>
    </b-modal>
  </b-card>
</template>
<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import filtersData from '@/views/assistance/views/dashboard/components/departamentalSchedules/views/data/filters.departamentalSchedules.data';
import Service from '@/views/assistance/views/dashboard/components/departamentalSchedules/services/departamentalSchedules.service';

export default {
  filters: {
    state(value) {
      switch (value) {
        case 0:
          return 'Presential';
        case 1:
          return 'Remote';
        case 2:
          return 'Weekend';
        default:
          return '';
      }
    },
  },
  data() {
    return {
      fields: ['updated_by', 'start_hour', 'end_hour', 'remote_state'],
      operation: 1,
      modal: false,
      departamentalSchedules: {},
      selectedDepartamentalSchedules: {},
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      sortBy: 'name',
      sortDesc: false,
      startPage: null,
      endPage: '',
      nextPage: '',
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Type day ...',
        model: '',
      },
      filter: filtersData,
      transactionId: null,
      newForm: {
        startHour: null,
        endHour: null,
        remoteState: '1',
      },
      timeOptions: [],
      type: [
        { value: 0, text: 'Presential' },
        { value: 1, text: 'Remote' },
        { value: 2, text: 'Weekend' },
      ],
      modalTitle: '',
      arrayErrorsSchedule: [],
      loading: true,
    };
  },

  computed: {
    visibleFields() {
      return this.fields;
    },
    moduleId() {
      return this.$route.matched[0].meta.module;
    },
    filterMonth() {
      return this.filter[3].model;
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  watch: {
    filterMonth() {
      this.getDayByMonth();
    },
  },
  async created() {
    await this.listAllUsers();
    await this.search();
    this.timeOptions = this.generateTimeOptions(null, null, 10); // start, end, interval
  },
  mounted() {
    this.getDayByMonth();
  },

  methods: {
    formatDayFunction(value) {
      switch (value) {
        case 1:
          return 'Monday';
        case 2:
          return 'Tuesday';
        case 3:
          return 'Wednesday';
        case 4:
          return 'Thursday';
        case 5:
          return 'Friday';
        case 6:
          return 'Saturday';
        case 7:
          return 'Sunday';
        default:
          // Handle any other cases or leave it as is
          return value;
      }
    },
    async copyHours(start, end) {
      const resultState = await this.showConfirmSwal();

      if (!resultState.isConfirmed) return;

      for (const element of this.departamentalSchedules) {
        element.start_hour = start;
        element.end_hour = end;
      }
    },
    async deleteHolidays(obj) {
      const resultState = await this.showConfirmSwal();

      if (resultState) {
        const resul = await Service.deleteHolidays(obj);

        if (resul.status === 200) {
          this.showSuccessSwal();
          this.refreshTable();
        }
      }
    },

    refreshTable() {
      this.$refs.refHolidaysTable.refresh();
    },

    async search() {
      try {
        this.loading = true;
        const obj = {
          module: this.$route.matched[0].meta.module,
        };

        const res = await Service.getDepartamentalSchedules(obj);
        if (res.status === 200) {
          this.departamentalSchedules = res.data;
        }
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
      return [];
    },
    async tracking(day) {
      try {
        const obj = {
          day,
          module: this.$route.matched[0].meta.module,
        };

        this.addPreloader();
        const res = await Service.trackingDepartamentalSchedules(obj);
        if (res.status === 200) {
          this.selectedDepartamentalSchedules = res.data;
          this.removePreloader();
          this.modalTitle = this.formatDayFunction(day);
          this.modal = true;
        }
      } catch (error) {
        console.log(error);
        this.removePreloader();
      }
      return [];
    },
    async listAllUsers() {
      try {
        const res = await Service.listAllUsers();
        if (res.status === 200) {
          this.filter[0].options = res.data;
        }
      } catch (error) {
        console.log(error);
      }
    },
    getDayByMonth() {
      const currentYear = moment().format('YYYY');
      const month = parseInt(this.filter[3].model, 10);
      const lastDay = month === 2
        ? 29
        : moment(`${currentYear}-${month}`, 'YYYY-MM').daysInMonth();
      this.filter[2].options = [];
      this.filter[2].options.push({ label: 'All', value: null });
      for (let index = 1; index <= lastDay; index += 1) {
        this.filter[2].options.push({ label: index, value: index });
      }
    },
    async updateDepartamentalSchedules() {
      try {
        if (!this.isValidSchedule()) {
          return;
        }
        const resultState = await this.showConfirmSwal();

        if (resultState.isConfirmed) {
          this.departamentalSchedules.operation = 2;
          this.departamentalSchedules.user_id = this.currentUser.user_id;

          this.addPreloader();

          const obj = {
            departamentalSchedules: JSON.stringify(this.departamentalSchedules),
            user_id: this.currentUser.user_id,
          };

          const resul = await Service.updateDepartamentalSchedules(obj);

          if (resul.status === 200) {
            this.showSuccessSwal();
            this.removePreloader();
            await this.search();
          }
        }
      } catch (error) {
        this.showErrorSwal();
        console.log(error);
        this.removePreloader();
      }
    },
    isValidSchedule(id = null) {
      let status = true;
      this.arrayErrorsSchedule = [];
      if (id) {
        const element = this.departamentalSchedules.find(item => item.id === id);
        if (element.end_hour <= element.start_hour) {
          status = status === true ? false : status;
          this.arrayErrorsSchedule.push(element.id);
        }
      } else {
        this.departamentalSchedules.forEach(element => {
          if (element.end_hour < element.start_hour) {
            status = status ? false : status;
            this.arrayErrorsSchedule.push(element.id);
          }
        });
      }
      return status;
    },
    async updateDepartamentalScheduleId(departamentalSchedule) {
      try {
        if (!this.isValidSchedule(departamentalSchedule.id)) {
          return;
        }
        const resultState = await this.showConfirmSwal();

        if (resultState.isConfirmed) {
          this.departamentalSchedules.operation = 2;
          this.departamentalSchedules.user_id = this.currentUser.user_id;

          this.addPreloader();

          const obj = {
            departamentalSchedule,
            user_id: this.currentUser.user_id,
          };

          const resul = await Service.updateDepartamentalScheduleId(obj);

          if (resul.status === 200) {
            this.showSuccessSwal();
            this.removePreloader();
            await this.search();
          }
        }
      } catch (error) {
        this.showErrorSwal();
        console.log(error);
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
  .table-no-horizontal-border td {
    border-top: none; /* Elimina el borde superior de las celdas */
    border-bottom: none; /* Elimina el borde inferior de las celdas */
    /* border-left: 1px solid #ddd; Agrega un borde izquierdo a las celdas */
    border-right: 1px solid #ddd; /* Agrega un borde derecho a las celdas */
  }

</style>
