export default [
  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Created by',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
  },

  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Repeat',
    model: null,
    options: [
      { value: null, label: 'All' },
      { value: 0, label: 'One time' },
      { value: 1, label: 'Always' },
    ],
    reduce: 'value',
    selectText: 'label',
    cols: 12,
  },

  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Day',
    model: null,
    options: [],
    reduce: 'value',
    selectText: 'label',
    cols: 6,
  },

  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Month',
    model: null,
    options: [
      { value: null, label: 'All' },
      { value: '01', label: 'January' },
      { value: '02', label: 'February' },
      { value: '03', label: 'March' },
      { value: '04', label: 'April' },
      { value: '05', label: 'May' },
      { value: '06', label: 'June' },
      { value: '07', label: 'July' },
      { value: '08', label: 'August' },
      { value: '09', label: 'September' },
      { value: '10', label: 'October' },
      { value: '11', label: 'November' },
      { value: '12', label: 'December' },
    ],
    reduce: 'value',
    selectText: 'label',
    cols: 6,
  },

  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Type',
    model: null,
    options: [
      { value: null, label: 'All' },
      { value: 1, label: 'Day Calendar' },
      { value: 2, label: 'Other' },
      { value: 3, label: 'Birthday' },
    ],
    reduce: 'value',
    selectText: 'label',
    cols: 12,
  },
];
